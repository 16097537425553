import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/privacy.scss";

class Shipping extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="shipping-page">
          <div className="title">InVision Privacy Policy</div>
          <div className="litter-title">EFFECTIVE DATE: AUGUST 23, 2023</div>
          <p>
            This Privacy Policy describes how InVisionApp Inc. and its
            affiliates (collectively, the ‘InVision’ or ‘we’ or ‘us’ or ‘our’)
            collect, use and disclose information about you and what choices you
            have with respect to your information.
          </p>
          <p>
            Your privacy is really important to us, so whether you’re new to
            InVision or a long-time user, please take the time to get to know
            our practices. We have made some updates to the format of this
            Privacy Policy to help you more easily find information. Our privacy
            practices have remained the same. Our previous privacy policy is
            available here. We have kept this simple to make it easy to
            understand, but if you are not familiar with any terms in this
            Privacy Policy, please reach out to us.
          </p>
          <p>
            If you do not agree with the terms of this Privacy Policy, do not
            access or use the Services, Websites or any other aspect of
            InVision’s business.
          </p>
          <p>
            Click on any of the links below to go straight to one of the
            following sections.
          </p>
          <p>OUR SERVICES</p>
          <p>
            We believe the screen is one of the most important places in the
            world. That’s why at InVision we are dedicated to helping you
            deliver the best possible digital product experience, with our
            platform and best practices from your peers.
          </p>
          <p>
            With intuitive tools for visual collaboration, design, prototyping,
            and ideating, the InVision platform gives you everything you need
            for digital product design and collaboration, all in one place.
          </p>
          <p>
            The InVision platform is accessible via our websites and mobile
            applications and allows its users, at core, (a) to upload their
            design concepts (.psds, .sketch, .jpgs, .pngs) and create
            interactive prototypes without writing a single line of code, and/or
            (b) to collaborate with their teammates and key stakeholders in real
            time or asynchronously using a shared online whiteboard
            (collectively, the “Platform”). These prototypes and whiteboards can
            then be shared internally & when needed with clients to capture
            feedback directly on the creative assets and/or projects.
          </p>
          <p>
            Our services are not intended for use by children. If we later learn
            that any user of our Service is under the age of 13 (or 16 in the
            EU, United Kingdom, Lichtenstein, Norway, or Iceland), we will take
            appropriate steps to remove that user’s information from our account
            database and will restrict that individual from future access to the
            Service.
          </p>
          <p>WHEN DOES THIS PRIVACY POLICY APPLY?</p>
          <p>
            This Privacy Policy sets forth the privacy practices of InVision
            for:
          </p>
          <p>
            1) InVision websites located at www.invisionapp.com,
            www.invision.com, www.freehandapp.com, designdisruptors.com,
            switchtosketchapp.com, labs.invisionapp.com, designbetter.co,
            go.invisionapp.com, and any other InVision websites or services that
            link to this Privacy Policy, (collectively, the ‘Websites’); 2) all
            InVision software and applications (including, without limitation,
            mobile software and applications) and all other InVision products or
            services provided or otherwise made accessible on or through the
            Software or the Websites or that otherwise link to or reference this
            Privacy Policy (collectively the ‘Services’).
          </p>
          <p>When this Privacy Policy does not apply:</p>
          <p>
            1) Our Websites may contain links to other websites. The information
            practices and the content of such other websites are governed by the
            privacy notices of such other websites. 2) Our Services also enable
            you to customize and connect the Services to Third-Party Services
            (including products, such as imagery, offered by such services). The
            collection of your information by these third parties is governed by
            the Third-Party Services’s privacy policies and terms. We recommend
            you carefully review their privacy policies and terms as InVision is
            not responsible for Third-Party Services.
          </p>
          <p>WHAT INFORMATION DO WE COLLECT AND RECEIVE?</p>
          <p>Information that you provide to us:</p>
          <p>
            Account Information: information we ask for and may collect to
            identify or contact you, such as your first and last name, physical
            address, email address, telephone number, avatar (if you choose to
            provide this) and IP address.
          </p>
          <p>
            Billing and Financial Account Information: information that you
            provide in connection with your purchase of the Services (or a
            purchase made through the Service), including credit card number,
            credit card expiration date, credit card verification code, bank
            account number, bank account title, bank name, branch location, and
            routing number. You must only provide us with Billing and Financial
            Account Information for accounts and credit cards that you have the
            lawful right to access. All payment information is handled only by
            PCI compliant organizations. When paying with a credit card we do
            store (or our payment processor on our behalf will store) just the
            last four digits of your credit card number to comply with credit
            card processing requirements of authorizations, charges and
            chargebacks.
          </p>
          <p>
            Transaction Information: information related to transactions you
            conduct on the Websites and/or Services, including when you choose
            to register for a webinar, event, or participate in a survey, or
            download special content, and your interactions with the Websites
            and/or Services (for example the functionality you use and the links
            clicked on the Services).
          </p>
          <p>
            User Content: when using the Services, your Customer Content (i.e.
            all the designs, Freehands, projects or prototypes that you decide
            to submit, upload or made available through InVision Platform) may
            contain, to the extent that you choose to input it, Personal Data
            (e.g. the names of Content contributors). The delivery, access and
            use of the Services is governed by a separate agreement between you
            and us.
          </p>
          <p>
            Important Note: As per the terms of the separate agreement between
            you and us regarding the use of our Services, Users of our Services
            should not use end-customer personal data (e.g. actual or “live”
            end-customer data) when building designs and prototypes. Industry
            practice is to use “dummy data” that does not refer to actual
            people. Please also do not provide us with any sensitive personal
            data while building your Freehands, designs and prototypes. For
            example, do not provide personal health information or personal
            financial information (except for limited financial data when
            purchasing the service as set forth above). If this type of data is
            necessary to make your prototype “come alive”, use dummy data
            instead. Please see our knowledge base for more information and a
            tool to easily import such dummy data.
          </p>
          <p>
            Information That We Collect From You through our Website and/or
            Services
          </p>
          <p>
            Log Data: information automatically recorded by the Services about
            how a person uses our Services and/or Websites, such as IP
            addresses, device and browser type, operating system, the pages or
            features of our Websites or Services to which a user browsed, the
            time spent on those pages or features, the frequency with which the
            Services is used by a user, search terms used by a user, the links
            on the Service that a user clicked on or used, and other statistics.
          </p>
          <p>
            Unique Identifiers: we use unique identifiers such as cookies,
            e-mail or your pseudonymized customer ID to track individual usage
            behavior on our Websites and/or Services, such as the length of time
            spent on a particular page and the pages viewed during a particular
            log-in period. Unique identifiers collect information about a user’s
            use of our Websites and/or Services on an individual basis.
          </p>
          <p>
            Mobile Device Identifiers: mobile device identifiers are identifiers
            stored on your mobile device that track certain data and activities
            occurring on or through your device. Mobile device identifiers
            enable collection of Personal Data (such as media access controls)
            as well as non-personally identifiable information (such as usage
            and traffic data).
          </p>
          <p>
            Cookies, Web Beacons, and Other Tracking Tools: we and our third
            party service providers collect information about you, your device,
            and your use of the Service through cookies, clear gifs (a.k.a. web
            beacons/web bugs) (“Web Beacons”), and other tracking tools and
            technological methods (collectively, “Tracking Tools”). Tracking
            Tools collect information such as computer or device operating
            system type, IP address, browser type, browser language, mobile
            device ID, device hardware type, the website or application visited
            or used before or after accessing our Service, the parts of the
            Service accessed, the length of time spent on a page or using a
            feature, and access times for a webpage or feature. These Tracking
            Tools help us learn more about our users and analyze how users use
            the Service, such as how often users visit our Service, what
            features they use, what pages they visit, what emails they open, and
            what other sites or applications they used prior to and after
            visiting the Service.
          </p>
          <p>
            Cookies: like many websites and mobile application operators, we
            collect certain information through the use of “cookies,” which are
            small text files that are saved by your browser when you access our
            Service. Cookies can either be “session cookies” or “persistent
            cookies”. Session cookies are temporary cookies that are stored on
            your device while you are visiting our Website or using our Service,
            whereas “persistent cookies” are stored on your device for a period
            of time after you leave our Website or Service. We use persistent
            cookies to store your preferences so that they are available for the
            next visit, and to keep a more accurate account of how often you
            visit our Service, and how your use of the Service varies over time.
            We also use persistent cookies to measure the effectiveness of
            advertising efforts. Through these cookies, we may collect
            information about your online activity after you leave our Service.{" "}
          </p>
          <p>
            For more information on cookies, including how to control your
            cookie settings and preferences, please review our Cookie Policy and
            visit:
          </p>
          <p>
            http://ec.europa.eu/ipg/basics/legal/cookies/index_en.html
            https://ico.org.uk/for-the-public/online/cookies/ and
            http://www.allaboutcookies.org/
          </p>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Shipping;
