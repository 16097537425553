import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/about.scss";

class About extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="about-page">
        
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default About;
