import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "../style/home.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import { productList } from "../apis/GetProduct";
import sec1 from "../image/sec1.png";
import x from "../image/x.svg";
import sec2 from "../image/sec2.png";
import sec3 from "../image/sec3.svg";
import sec3_1 from "../image/sec3-1.svg";
import sec3_2 from "../image/sec3-2.svg";
import sec3_3 from "../image/sec3-3.svg";
import sec3_4 from "../image/sec3-4.svg";
import cross from "../image/icon_cross.svg";
import circle from "../image/icon_circle.svg";
import stance1_icon from "../image/stance1_icon.svg";
import stance2_icon from "../image/stance2_icon.svg";
import flow from "../image/flow.svg";
import flow1 from "../image/flow1.svg";
import sec5_icon from "../image/sec5-icon.png";
import sec5 from "../image/sec5.png";
import sec5_1 from "../image/sec5-1.png";
import sec5_2 from "../image/sec5-2.png";
import sec5_3 from "../image/sec5-3.png";
import sec5_4 from "../image/sec5-4.png";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.routePage = this.routePage.bind(this);
    this.routeProductDetail = this.routeProductDetail.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      case "net":
        history.push("/Net");
        break;
      default:
        break;
    }
  }

  routeProductDetail(id) {
    let { history } = this.props;
    history.push(`/ProductDetail/${id}`);
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-page">
          <div className="header">
            <div className="left">
              <div className="logo">Swooo</div>
              <div className="p">国内初！bubble公式開発試験に合格！</div>
            </div>
            <div className="right">
              <div className="green btn" onClick={()=>this.routePage('contact')}>資料請求</div>
              <div className="blue btn" onClick={()=>this.routePage('net')}>即時見積もり</div>
            </div>
          </div>
          <div className="sec1">
            <div className="contain">
              <div className="img">
                <img src={sec1} alt="" />
              </div>
              <div className="text-mask">
                <div className="top">国内最大級のbubble受託開発会社 Swooo</div>
                <div className="t-title">ノーコード(bubble)の実績多数！</div>
                <div className="title">
                  新規事業<span>のプロダクト開発</span>
                </div>
                <p>
                  bubble等のノーコードツールを用いて <br />
                  通常の1/3程度のコスト、期間で開発いたします
                </p>
                <div className="li-row">
                  <div className="fff">
                    ノーコード <br />
                    開発
                  </div>
                  <img src={x} alt="" className="x" />
                  <div className="fff">
                    プロダクト <br />
                    企画
                  </div>
                  <img src={x} alt="" className="x" />
                  <div className="fff">
                    マーケ
                    <br />
                    戦略
                  </div>
                </div>
                <div className="btn" onClick={()=>this.routePage('net')}>
                  無料! 即時見積もり
                  <div className="up-btn">2分で完了</div>
                </div>
              </div>
            </div>
          </div>
          <div className="sec2">
            <img src={sec2} alt="" />
          </div>
          <div className="sec3">
            <div className="title">REASON</div>
            <div className="lit-title">Swooo(スゥ-)が選ばれる理由</div>
            <div className="row1">
              <img src={sec3} alt="" />
              <div className="right">
                <div className="title">日本最大級の実績から品質が高い</div>
                <p>
                  豊富な経験、圧倒的当事者意識をもったPMが貴社のビジネスに伴走いたします。納品が目的ではなくビジネスの成功のため、アタリマエ品質を高く開発を行えます。
                  また、これまで支援してきた数十社の会社全てにおいて、納品時に手戻りが発生せず、満足度高く納品をさせていただいております。
                </p>
                <p>詳しくは実績をご覧ください</p>
              </div>
            </div>
            <div className="row1">
              <div className="right">
                <div className="title">エンジニア観点で事業を伴走する</div>
                <p>
                  弊社では開発の完了が終わりではなく、ビジネスの始まりだと考えています。
                  ビジネスを大きくするために1エンジニアとして事業の伴走パートナとして注力いたします。
                </p>
                <p>詳しくはプロジェクトの進め方をご覧ください</p>
              </div>
              <img src={sec3_1} alt="" />
            </div>
            <div className="row1">
              <img src={sec3_2} alt="" />
              <div className="right">
                <div className="title">
                  通常開発の約1/3程度のコストで開発が可能
                </div>
                <p>
                  ノーコード・ローコードと呼ばれるコーディング不要の開発ツールを活用することで、従来よりも低いコストながらも高品質なシステムの導入・構築・内製化が可能になります。
                </p>
                <p>詳しくはプロジェクトの進め方とサービスをご覧ください</p>
              </div>
            </div>
            <div className="row1">
              <div className="right">
                <div className="title">
                  独自プラグイン開発 / API開発など、 システム開発もできる開発力
                </div>
                <p>
                  ノーコードだけでは細かい箇所で「痒い箇所に手が届かない」瞬間があります。
                  それに対して我々はbubble内部でJavaScriptを記載することや、自社でAPIを作成して繋ぎこむ等、目的に応じて柔軟に対応が可能です。{" "}
                </p>
                <p>実績：独自プラグインの作成, 国内最大のbubble-Udemy講師</p>
              </div>
              <img src={sec3_3} alt="" />
            </div>
            <div className="row1">
              <img src={sec3_4} alt="" />
              <div className="right">
                <div className="title">bubble公認エージェンシー認定</div>
                <p>
                  ノーコード開発の可能性を広げるbubbleのさらなる普及を目指して、bubble
                  Group, Inc.の正式エージェンシーとして認定されております。
                </p>
                <p>実績：bubbleリファレンス</p>
              </div>
            </div>
            <div className="btn" onClick={()=>this.routePage('net')}>
              無料! 即時見積もり
              <div className="up-btn">2分で完了</div>
            </div>
          </div>
          <div className="sec4">
            <div className="title">DEVELOP</div>
            <div className="lit-title">Swooo(スゥ-)における開発</div>
            <div className="title1">開発に関わるスタンス</div>
            <div className="row">
              <div className="col">
                <img src={cross} alt="" />
                <p>
                  1開発メンバーとしてコミット
                  <br />
                  一般的な受託開発会社のように開発支援のみ
                </p>
                <div className="right-top">
                  <img src={stance1_icon} alt="" />
                </div>
              </div>
              <div className="col">
                <img src={circle} alt="" />
                <p>
                  1事業メンバーとしてコミット
                  <br /> 市場調査、登り方整理からKAIZENまで
                </p>
                <div className="right-top">
                  <img src={stance2_icon} alt="" />
                </div>
              </div>
            </div>
            <div className="title1">開発の進め方</div>
            <p>
              基本的には、以下のように4つのフェーズに分けてプロジェクトを推進していきます。
              <br />
              特に要件定義フェーズから始めずに、プロジェクト（事業や社内PJ）をどう進めていくのが良いか？を考える「登り方整理」が弊社特有のフェーズであり、最大の特徴です。
            </p>
            <div className="img">
              <img src={flow} alt="" />
            </div>
            <div className="img">
              <img src={flow1} alt="" />
            </div>
            <div className="btn" onClick={()=>this.routePage('net')}>
              無料! 即時見積もり
              <div className="up-btn">2分で完了</div>
            </div>
          </div>
          <div className="sec5">
            <div className="contain">
              <div className="title">SERVICE</div>
              <div className="lit-title">サービス</div>
              <div className="title1">
                Swooo（スゥー）ではbubbleを用いた開発支援を主軸にさまざまなサービスを提供しております。
                <br />
                全て戦略立案のみではなく、実働も提供する伴走型コンサルティングサービスです。
              </div>
              <div className="row">
                <div className="col">
                  <div className="title">新規事業伴走 & bubble開発</div>
                  <p>
                    支援領域：プロダクト開発（デザイン、開発、テスト、保守）
                  </p>
                  <p>体制：PdM、PjM、デザイナー、エンジニア</p>
                  <div className="ul">
                    <div className="li">
                      <img src={sec5_icon} alt="" />
                      <p>Bubbleを用いた開発支援</p>
                    </div>
                    <div className="li">
                      <img src={sec5_icon} alt="" />
                      <p>早く、安く、高品質な開発を提供</p>
                    </div>
                    <div className="li">
                      <img src={sec5_icon} alt="" />
                      <p>セキュリテイの高いbubbleを使用</p>
                    </div>
                    <div className="li">
                      <img src={sec5_icon} alt="" />
                      <p>PMF達成に向けた事業伴走</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="title">bubble開発支援</div>
                  <p>
                    支援領域：プロダクト開発（デザイン、開発、テスト、保守）
                  </p>
                  <p>体制：PdM、PjM、デザイナー、エンジニア</p>
                  <div className="ul">
                    <div className="li">
                      <img src={sec5_icon} alt="" />
                      <p>Bubbleを用いた開発支援</p>
                    </div>
                    <div className="li">
                      <img src={sec5_icon} alt="" />
                      <p>早く、安く、高品質な開発を提供</p>
                    </div>
                    <div className="li">
                      <img src={sec5_icon} alt="" />
                      <p>セキュリテイの高いbubbleを使用</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="title">新規事業マーケティング支援</div>
                  <div className="ul">
                    <div className="li">
                      <img src={sec5_icon} alt="" />
                      <p>新規事業のマーケティング戦略立案</p>
                    </div>
                    <div className="li">
                      <img src={sec5_icon} alt="" />
                      <p>マーケティング実行支援</p>
                    </div>
                    <div className="li">
                      <img src={sec5_icon} alt="" />
                      <p>テストマーケティング支援</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="title">CTO壁打ちプラン</div>
                  <div className="ul">
                    <div className="li">
                      <img src={sec5_icon} alt="" />
                      <p>新規事業の企画立案にCTOとして伴走</p>
                    </div>
                    <div className="li">
                      <img src={sec5_icon} alt="" />
                      <p>技術選定・MVP設計支援</p>
                    </div>
                    <div className="li">
                      <img src={sec5_icon} alt="" />
                      <p>マーケティング計画</p>
                    </div>
                    <div className="li">
                      <img src={sec5_icon} alt="" />
                      <p>マネタイズモデル設計</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="title">BtoB研修</div>
                  <div className="ul">
                    <div className="li">
                      <img src={sec5_icon} alt="" />
                      <p>ノーコードを体系的に・効率的に学習</p>
                    </div>
                    <div className="li">
                      <img src={sec5_icon} alt="" />
                      <p>DX研修</p>
                    </div>
                    <div className="li">
                      <img src={sec5_icon} alt="" />
                      <p>動画教材を用いた学習</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="title">WORKS</div>
              <div className="lit-title">Swoooの過去の実績</div>
              <img src={sec5} alt="" />
              <img src={sec5_1} alt="" />
              <img src={sec5_2} alt="" />
              <img src={sec5_3} alt="" />
              <img src={sec5_4} alt="" />
            </div>
          </div>
          <div className="sec6">
            <div className="title">Q&A</div>
            <div className="lit-title">よくあるご質問</div>
            <div className="q">
              <span>Q.</span> ノーコードとは何ですか？
            </div>
            <div className="a">
              ノーコードとは、コーディング不要の開発ツール（コードを書かずにアプリケーションを開発するツールのこと）の総称です。弊社ではお客様の目的に応じて様々なノーコードツールを用いた開発をさせていただいております。
            </div>
            <div className="q">
              <span>Q.</span> なぜコストを抑えて作れるのですか？
            </div>
            <div className="a">
              ノーコードを活用し、コーディングコストを抑えて開発を進められるためです。加えて、綿密なコミュニケーションを取ることで、手戻りを少なくしているためです。
            </div>
            <div className="q">
              <span>Q.</span>
              ミーティングは週1ですか？
            </div>
            <div className="a">
              基本的には週1回をベースにプロジェクトを進めていきます。しかし、要望や開発内容次第で柔軟に対応可能です。
            </div>
            <div className="q">
              <span>Q.</span>
              プロジェクトをすぐに開始したいのですが、対応可能ですか？
            </div>
            <div className="a">
              案件内容にもよりますが、お問い合わせを頂いた週のうちに開始したプロジェクトもございます。
              お急ぎの場合は、お問い合わせフォームよりタイトルや本文にお急ぎである旨を記載の上、お問い合わせください。
            </div>
            <div className="q">
              <span>Q.</span>
              こちらで用意するものはありますか？
            </div>
            <div className="a">
              ヒアリング時にサービスの概要が分かる資料などを提供していただけるとスムーズです。また、開発にあたりノーコードツールや外部ツールを使用するために必要なアカウントを発行していただく可能性がございます。{" "}
            </div>
            <div className="q">
              <span>Q.</span>
              セキュリティ面での不安はありますか？
            </div>
            <div className="a">
              弊社が主に利用させていただくノーコードツールに関しては、AWSやAzureなど大手金融機関でも用いられている開発・実行環境が提供されるため、システムそのものの堅牢性が低いといったことはございません。{" "}
            </div>
            <div className="q">
              <span>Q.</span>
              ノーコードのデメリットはありますか？
            </div>
            <div className="a">
              MVP開発時点では無いと言えると思います。同時に1万人がアクセスする事を想定する等の大規模な開発を行うにあたっては、ノーコードでは処理性能に限界があります。また、SEOに不向きではありますが、対策は可能です。{" "}
            </div>
            <div className="btn" onClick={()=>this.routePage('net')}>
              無料! 即時見積もり
              <div className="up-btn">2分で完了</div>
            </div>
          </div>
        </div>
        {/* <FooterBar {...this.props} /> */}
      </React.Fragment>
    );
  }
}

export default Home;
