import React, { Component } from "react";
import "../style/signIn.scss";
import sign from "../image/sign.png";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.routePage = this.routePage.bind(this);
  }
  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "cart":
        history.push("/Cart");
        break;
      case "signIn":
        history.push("/SignIn");
        break;
      case "signUp":
        history.push("/SignUp");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="signIn-page">
          <div className="left">
            <div className="img">
              <img src={sign} alt="" />
            </div>
            <div className="bottom-box">
              <p>InVision Freehand Enterprise</p>
              <h3>
                Transformative collaboration for <br /> larger teams
              </h3>
              <div className="btn">Schedule a demo</div>
            </div>
          </div>
          <div className="right">
            <div className="head">
              Don’t have an account?{" "}
              <div className="btn" onClick={() => this.routePage("signUp")}>
                Get started
              </div>
            </div>
            <div className="contain">
              <h1>Sign in to InVision</h1>
              <h6>Enter your details below</h6>
              <p>Email address</p>
              <input type="text" />
              <p>Password</p>
              <input type="text" />
              <div className="btn">Sign in</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SignIn;
