import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/terms.scss";

class Terms extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="return-page">
          <div className="title">InVision Terms of Service</div>
          <div className="litter-title">
            EFFECTIVE DATE OF TERMS OF SERVICE: NOVEMBER 4, 2019
          </div>
          <p>
            Please read these "Terms of Service" fully and carefully before
            using https://www.invisionapp.com, www.freehandapp.com (or any other
            InVision website linking to these Terms of Service (the "InVision
            Website") and any of the services, InVision Content (defined below),
            platforms, software, applications, documentation or training
            materials provided by InVisionApp Inc. and its affiliates,
            (“InVision”, "we", "us" or "our") in connection with InVision’s
            visual collaboration and design platform (the "Platform") or any
            other InVision service or platform that links to these Terms of
            Service or that is provided by InVision in connection therewith
            (collectively, the "Services").
          </p>
          <p>
            These Terms of Service incorporate InVision’s Privacy Policy, which
            is hereby made a part of these Terms of Service.
          </p>
          <p>
            These Terms of Service set forth the legally binding terms and
            conditions for your use of the Services. By clicking on the
            “Accept,” "sign up free," "upgrade your plan," or equivalent access,
            agreement or purchasing button, or by way of an email confirmation
            between InVision and you or the company or entity that you represent
            ("you," "your," "yours" or "Customer") that includes these Terms of
            Service by reference, you are consenting to be bound by and are
            becoming a party to these Terms of Service. You are also
            representing and warranting that the individual clicking on the
            button or otherwise consenting is authorized to enter into this
            agreement and bind such entity. Your continued use of any portion of
            the services shall also constitute assent to the terms of these
            Terms of Service including any modifications hereto. If you are
            using the services on behalf of a company or other entity, then all
            references to "you", “your” or "yours" herein shall refer to both
            the individual and the entity.
          </p>
          <p>
            If you do not unconditionally agree to all of the terms of these
            Terms of Service, you will have no right to use the Services (and
            you should immediately cease all such use). If these terms are
            considered an offer, acceptance is expressly limited to these terms
            to the exclusion of all other terms.
          </p>
          <p>
            For the avoidance of doubt, all free subscriptions are subject to
            this version of the Terms of Service . If you do not agree to these
            terms, please cancel your subscription. If you are using a screen
            reader and are having problems using this website, please contact
            InVision Support.
          </p>
          <p>
            ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT FOR CERTAIN TYPES
            OF DISPUTES DESCRIBED IN THE ARBITRATION SECTION BELOW, YOU AGREE
            THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING,
            INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A
            CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. PLEASE SEE SECTION
            15 BELOW FOR ADDITIONAL INFORMATION.
          </p>
          <p>Acceptance of Terms of Service.</p>
          <p>
            By registering for and/or using any of the Services in any manner,
            including visiting or browsing the InVision Website, you agree to
            these Terms of Service, and all other operating rules, policies, and
            procedures that we may publish from time to time through the
            Services, each of which is incorporated by reference, and each of
            which may be updated from time to time without notice to you.
          </p>
          <p>
            Certain of the Services may be subject to additional terms and
            conditions specified by us from time to time; your use of such
            Services is subject to those additional terms and conditions, which
            are incorporated into these Terms of Service by this reference.
          </p>
          <p>
            These Terms of Service apply to all users of the Services,
            including, without limitation, users who are contributors of
            content, information, and other materials or services, registered or
            otherwise. Please note that if you have executed a separate written
            agreement with InVision regarding your use of certain of our
            Services (a "Separate Agreement"), then that Separate Agreement, and
            not these Terms of Service, applies to those Services, until such
            time that such Separate Agreement is replaced by these Terms of
            Service by way of your consent and/or by downgrading to a free
            subscription. Notwithstanding the foregoing, if you opt to use any
            Beta Services (defined below) or InVision Multimedia Content
            (defined below), such use will be subject to these Terms of Service
            (and any other different or additional legal terms presented to you
            in association with such use), even if you have executed a Separate
            Agreement.
          </p>
          <p>
            Eligibility. You represent and warrant that you are at least 13
            years of age. If you are under age 13, you may not, under any
            circumstances or for any reason, register for an Account (defined
            below) or use the Services. Furthermore, you must be at least 18
            years of age to use any Premium Services or any other portion of the
            Services that requires you to make any payment. We may, in our sole
            discretion, refuse to offer the Services to any person or entity and
            change its eligibility criteria at any time. You are solely
            responsible for ensuring that these Terms of Service and your use of
            the Services (a) are in compliance with all laws, rules and
            regulations applicable to you and (b) do not violate any other
            agreement to which you are a party. The right to access the Services
            is revoked where these Terms of Service or use of the Services is
            prohibited or to the extent offering, sale or provision of the
            Services conflicts with any applicable law, rule or regulation.
            Further, the Services are offered only for your use, and not for the
            use or benefit of any third party.
          </p>
          <p>
            Registration and User Seats. To sign up for the Services, you must
            register for an account on the Services (an "Account"). You must
            provide accurate and complete information for your Account. For
            Services licensed on a per-seat or per-member basis, access will be
            limited to the number of Users (defined below) for whom you have
            purchased seats (plus, to the extent permitted, any additional Users
            that are added to your Account, for whom you will be billed as set
            forth below). Unless you have purchased an Account that expressly
            licenses multiple User seats, each Account shall only be used by the
            individual user that registered such Account. You may never use
            another person’s user Account without permission from InVision, or
            share your Account or access credentials with any other individual
            or entity. You should never publish, distribute, or post login
            information for your Account. "User" means an individual (a natural
            human!) whom you authorize to use the Services. Users may include,
            for example, your employees, coworkers, consultants, contractors and
            agents, and third parties with which you transact business. A User’s
            access credentials may not be shared with any other individual or
            entity for purposes of using the Services. You are responsible for
            maintaining the security of your Account, passwords (including, but
            not limited to, administrative and user passwords). You shall be
            responsible for acts, omissions, or breaches hereunder by any of
            your Users or any other individuals using your Account or
            credentials for the Services. You must keep your Account information
            updated, and you must notify us immediately of any change in your
            eligibility to use the Services or breach of security or
            unauthorized use of your Account. Users may have the ability to
            invite other users to their teams, unless the applicable Account
            settings restrict this functionality. We will provide you with
            periodic updates of its list of active Users, and you may request
            this list from us. <br /> Please note that if you are a User of an
            Account purchased by your employer or another person or entity, then
            these Terms of Service allow such person or entity to provision
            additional services, or to change or limit your access to the
            Services at any time. It is up to that person or entity to inform
            you of any particular rules, requirements or limitations regarding
            your use beyond those set forth in these Terms of Service. You and
            such person or entity agree to look solely to such person or entity
            regarding any dispute relating to such additional rules,
            requirements, or limitations.
          </p>
          <p>The Services.</p>
          <p>
            Services. Subject to these Terms of Service, InVision will use
            commercially reasonable efforts to make the Services available to
            you.
          </p>
          <p>Content.</p>
          <p>
            "Content" means information, data, templates, UI kits, interfaces,
            utilities, forms, documentation, content, tools, features, text,
            fonts, photographs, videos, audio clips, written posts and comments,
            software, scripts, and graphics.
          </p>
          <p>
            “InVision Template Content” means Content made accessible by
            InVision via the Platform expressly for use by customers in creating
            value-added Customer Data (for the avoidance of doubt, excluding any
            Content made available via any third party services as set forth
            below). An example of InVision Template Content would be a Freehand
            template provided by InVision through the Platform for use by
            customers in creating a digital asset). You acknowledge that all
            Content accessed by you using the Services is at your own risk and
            you will be solely responsible for any damage or loss to you or any
            other party resulting therefrom. Subject to these Terms of Service,
            InVision grants to you and your Users a worldwide, non-exclusive,
            sublicensable, transferable, right and license to use, copy, access,
            process, reproduce, perform, display, transmit, modify and make
            derivative works from, InVision Template Content made available by
            the Platform, solely to (i) incorporate the InVision Template
            Content into value-added Customer Data, and (ii) to distribute such
            InVision Template Content as incorporated into the value-added
            Customer Data (InVision Template Content may not be resold or
            redistributed except as part of a value-added Customer product or
            service.
          </p>
          <p>
            “InVision Multimedia Content” means ebooks, podcasts, videos and
            other media made accessible by InVision via the InVision Website.
            InVision grants you a worldwide, non-exclusive, non-sublicensable,
            revocable, and non-transferable license to use (i.e. to download and
            display locally) the InVision Multimedia Content, solely for
            purposes of your internal use (i.e. you may not resell or otherwise
            distribute the books, videos or podcasts).
          </p>
          <p>
            “InVision Content” means InVision Template Content and InVision
            Multimedia Content.
          </p>
          <p>
            Restrictions on Use of the Services. You will not directly or
            indirectly:
          </p>
          <p>
            sell, license, sublicense, distribute, copy, rent, or lease the
            Services, or include the Services in a service bureau, time-share
            outsourcing offering, or otherwise make the Services available to,
            or use the Services for the benefit of, any third party, or transfer
            any of the rights that you receive hereunder;
          </p>
          <p>
            interfere with or disrupt the integrity or performance of the
            Services or any third-party data contained therein or attempt to
            gain unauthorized access to the Services or its related systems or
            networks;
          </p>
          <p>
            copy, modify, translate, or create derivative works based on the
            Services or any underlying software or any part, feature, function,
            or user interface thereof, including by framing (except as expressly
            allowed by InVision) or mirroring any part of any Services or any
            InVision Content;
          </p>
          <p>
            access or use the Services for benchmarking or similar competitive
            analysis purposes or in order to build a competitive product or
            service;
          </p>
          <p>
            decompile, disassemble, decipher, or reverse engineer the Services,
            or otherwise attempt to derive any source code or underlying ideas
            or algorithms of any part of the Services, (except to the extent
            such restriction is prohibited by applicable statutory law);
          </p>
          <p>
            remove any copyright notices, information, and restrictions
            contained in the Services or any InVision Content;
          </p>
          <p>
            bypass, circumvent, or attempt to bypass or circumvent any measures
            we may use to prevent or restrict access to the Services (or other
            accounts, computer systems or networks connected to the Services) or
            take any action that imposes or may impose (as determined by us in
            our sole discretion) an unreasonable or disproportionately large
            load on our (or our third-party providers’) infrastructure;
          </p>
          <p>
            use manual or automated software, devices, or other processes to
            "crawl" or "spider" any page of the InVision Website or Services; or
          </p>
          <p>
            otherwise take any action in violation of our guidelines and
            policies, including these Terms of Service.
          </p>
          <p>
            Misuse of the Services. InVision may suspend access to the Services
            by you or any User pending an investigation of misuse.
          </p>
          <p>
            Availability. We are not and will not be responsible or liable for
            any failure in the Services resulting from or attributable to (i)
            Customer Data or failures to deliver Customer Data to InVision; (ii)
            failures in any telecommunications, network or other service or
            equipment outside of InVision’s facilities; (iii) your or any third
            party’s (acting on your behalf) products, services, negligence,
            willful misconduct, breach of these Terms of Service or other
            unauthorized access or use; or (iv) any force majeure or other cause
            beyond InVision’s reasonable control. Users are responsible for
            testing and reviewing the results they obtain from using the
            Services. We do not guarantee that the Services or any InVision
            Content will be available, or that any InVision Content that is
            available is or will continue to be accurate. We reserve the right,
            but do not have any obligation, to remove, edit, modify, or block
            access to any Content in our sole discretion, at any time, without
            notice to you and for any reason (including upon receipt of claims
            or allegations from third parties or authorities relating to such
            Content or if we are concerned that you may have violated these
            Terms of Service).
          </p>
          <p>
            Limits. We may impose certain limits on the use of the Services,
            including usage limits for different Service plans, including
            without limitation, the amount of Customer Data that may be stored,
            features that may be accessed number of Users or amount of Content
            accessed or linked to, or other rate or usage limitations at any
            time in our sole discretion.
          </p>
          <p>
            API. InVision has developed and provides access to an API which may
            be used to create applications for inclusion in the InVision Studio
            App Store. You may only use this API in accordance with our Studio
            App Store Maker Terms of Use.
          </p>
          <p>
            Team Subdomains. The Services may permit you to create team
            subdomains. When naming your teams and subdomain URLs, please be
            aware that InVision does not permit the impersonation of other
            brands, organizations, products, etc. InVision reserves the right to
            make changes to team names and subdomain URLs if it believes that
            you are in violation of this policy or as otherwise to respect third
            party rights.
          </p>
          <p>
            Beta Services. From time to time InVision may offer you access to
            alphas, betas, limited releases, or other pre-GA InVision services
            or features (collectively “Beta Services”). Beta Services may have
            limited features, functions, data storage, data security, data
            retention or other limitations as determined by InVision. You
            acknowledge and agree that Beta Services may not be complete or
            fully functional, and may contain bugs, errors, omissions, and other
            problems for which InVision will not be responsible. Accordingly,
            any use of Beta Services is at your sole risk.
          </p>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Terms;
