import React, { Component } from "react";
import "../style/security.scss";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import Check from "../icon/Check";

class Security extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="security-page">
          <div className="sec1">
            <div className="title">
              Never compromise on <br /> security.
            </div>
            <p>
              100% of Fortune 100 companies use InVision to power a <br />
              collaborative and scalable cloud-based workflow.
            </p>
            <div className="btn">Talk to us about security</div>
          </div>
          <div className="sec2">
            <div className="col">
              <div className="title">
                Flexible access <br /> management puts IT in <br /> the driver’s
                seat.
              </div>
              <p>
                Our advanced security integrations fold seamlessly into your
                corporate policies and workflow.
              </p>
            </div>
            <div className="col">
              <div className="li">
                <Check size={30} color="#0074FF" />
                <p>
                  Auto-provisioning and <br /> deprovisioning via SCIM
                </p>
              </div>
              <div className="li">
                <Check size={30} color="#0074FF" />
                <p>
                  Single-Sign-On (SSO) and <br /> IP whitelisting
                </p>
              </div>
              <div className="li">
                <Check size={30} color="#0074FF" />
                <p>
                  Multi-factor Authentication <br /> (MFA)
                </p>
              </div>
            </div>
          </div>
          <div className="sec2">
            <div className="col">
              <div className="title">Audited security controls.</div>
              <p>
                Best of breed data protection and security practices, validated
                annually by reputable third parties.
              </p>
            </div>
            <div className="col">
              <div className="li">
                <Check size={30} color="#0074FF" />
                <p>
                  SOC 2 Type 2 annual <br /> attestation
                </p>
              </div>
              <div className="li">
                <Check size={30} color="#0074FF" />
                <p>
                  EU-US Privacy Shield <br /> compliant
                </p>
              </div>
              <div className="li">
                <Check size={30} color="#0074FF" />
                <p>
                  Payment Card Industry <br /> (PCI) certified
                </p>
              </div>
            </div>
          </div>
          <div className="sec2">
            <div className="col">
              <div className="title">
                Intellectual property <br /> protection.
              </div>
              <p>
                Consolidating private plans into a company-managed account helps
                ensure that you retain your work as employees come and go.
              </p>
            </div>
            <div className="col">
              <div className="li">
                <Check size={30} color="#0074FF" />
                <p>No seat limitations</p>
              </div>
              <div className="li">
                <Check size={30} color="#0074FF" />
                <p>
                  Retain all data when <br /> employees leave
                </p>
              </div>
              <div className="li">
                <Check size={30} color="#0074FF" />
                <p>
                  Enhanced user audit <br /> logging
                </p>
              </div>
            </div>
          </div>
          <div className="sec3">
            <div className="title">
              Security that flexes to <br /> your needs.
            </div>
            <p>
              InVision offers built-in security across all InVision plans as{" "}
              <br />
              well as enterprise-specific security features and <br />{" "}
              customization options.
            </p>
          </div>
          <div className="sec4">
            <div className="title">All plans include</div>
            <div className="row">
              <div className="col">
                <div className="li">
                  <Check size={30} color="#0074FF" />
                  <p>Data encryption in transit and at rest</p>
                </div>
                <div className="li">
                  <Check size={30} color="#0074FF" />
                  <p>Web application firewall</p>
                </div>
              </div>
              <div className="col">
                <div className="li">
                  <Check size={30} color="#0074FF" />
                  <p>Container security</p>
                </div>
                <div className="li">
                  <Check size={30} color="#0074FF" />
                  <p>DDoS protection</p>
                </div>
                <div className="li">
                  <Check size={30} color="#0074FF" />
                  <p>Intrusion detection / prevention</p>
                </div>
              </div>
            </div>
          </div>
          <div className="sec4">
            <div className="title">Enterprise</div>
            <p>Support links provided if applicable.</p>
            <div className="row">
              <div className="col">
                <div className="li">
                  <Check size={30} color="#0074FF" />
                  <p>SCIM provisioning</p>
                </div>
                <div className="li">
                  <Check size={30} color="#0074FF" />
                  <p>SAML-based SSO</p>
                </div>
                <div className="li">
                  <Check size={30} color="#0074FF" />
                  <p>IP whitelisting</p>
                </div>
                <div className="li">
                  <Check size={30} color="#0074FF" />
                  <p>
                    Multi-factor Authentication <br /> enforcement
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="li">
                  <Check size={30} color="#0074FF" />
                  <p>Audit logs</p>
                </div>
                <div className="li">
                  <Check size={30} color="#0074FF" />
                  <p>
                    Customizable password <br /> requirements and session
                    timeouts
                  </p>
                </div>
                <div className="li">
                  <Check size={30} color="#0074FF" />
                  <p>
                    Sharing password protected <br /> documents
                  </p>
                </div>
                <div className="li">
                  <Check size={30} color="#0074FF" />
                  <p>
                    Advanced Admin roles and user <br /> permissions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Security;
