export const productList = [
  {
    id: 1,
    title: "ENJOY! GREEN GUIDE",
    img: "",
    image: [],
    link: "https://www.tokyu-green-resort.com/",
    description: [
      "店舗・施設紹介",
      "商品・サービス紹介",
      "情報・メディア発信",
      "観光・旅行",
      "アニメーション",
      "シンプル",
      "やさしい",
      "かわいい",
      "ポップ",
      "イラスト",
      "黄色",
      "緑色",
      "やさしい・やわらかい色",
    ],
    txt: "大自然で思いっきり楽しみたい皆さんへ、「体感型サステナブルリゾート」ガイドです。ワクワクするアクティビティからおいしい地元の食事まで、楽しみながらサステナブルな意識も芽生える思い出の旅へご案内します。",
  },
  {
    id: 2,
    title: "いなべ阿下喜ベース",
    img: "",
    image: [],
    link: "https://inabe-ageki-base.com/",
    description: [
      "店舗・施設紹介",
      "ホテル・旅館・温泉",
      "アニメーション",
      "シンプル",
      "やさしい",
      "かわいい",
      "ロゴが印象的",
      "控えめな配色",
    ],
    txt: "15年続いた阿下喜温泉あじさいの里が、温泉・サウナ・宿泊・食事からなる街の温泉複合施設 『いなべ阿下喜ベース』に生まれ変わりました。 いままで阿下喜温泉に通っていた地元の方も、市外からいなべ市に遊びに来られる方も楽しめる 施設として、今まで人気だった温泉はそのままに、サウナエリアやコンテナタイプの宿泊、地元のお野菜が楽しめる食堂が加わりました。 「いなべ阿下喜ベース」は、『自然と健康』をコンセプトに、いなべの自然資源をとりいれて、キャンプや登山などのアウトドア、すぐ近くの阿下喜商店街の街歩きなど、健康につながる体験の提供と観光客の市内周遊の拠点(ベース)として展開していきます。",
  },
];

export const getProductDetailById = (id) => {
  for (let i = 0; i < productList.length; i++) {
    if (productList[i].id === id) {
      return productList[i];
    }
  }
};

export const getRandomElements = (id) => {
  const result = [];
  const arrayCopy = [...productList]; // 创建数组的副本，以避免修改原始数组

  for (let i = 0; i < 4; i++) {
    let randomIndex;

    // 确保随机索引不与给定的 id 相同
    do {
      randomIndex = Math.floor(Math.random() * arrayCopy.length);
    } while (productList[randomIndex].id === id);

    const element = arrayCopy.splice(randomIndex, 1)[0];
    result.push(element);
  }

  return result;
};
