import React, { Component } from "react";
import "../style/net.scss";
import sec6 from "../image/sec6.png";
import sec6_1 from "../image/sec6-1.svg";
import queryString from "query-string";

class Net extends Component {
  constructor(props) {
    super(props);
    this.state = {
      row1: false,
      row2: false,
    };
    this.routePage = this.routePage.bind(this);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      case "net":
        history.push("/Net");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="net-page">
          <div className="header" onClick={() => this.routePage("home")}>
            Swooo
          </div>
          <div className="box">
            <div className="head">
              <div className={`mask w1 `}></div>
            </div>
            <div className="body-contain">
              <div className="title">対象ユーザーを選択</div>
              <p>アプリの対象ユーザーを選択してください。</p>
              <div className="row1">
                <div
                  className="col"
                  //   onClick={() => this.clickToNote("general")}
                >
                  <img src={sec6} alt="" />
                  <p>一般消費者・社外向け</p>
                </div>
                <div
                  className="col"
                  //   onClick={() => this.clickToNote("general")}
                >
                  <img src={sec6_1} alt="" />
                  <p>社内向け</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Net;
