import React, { Component } from "react";
import "../style/signUp.scss";
import sign from "../image/sign.png";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.routePage = this.routePage.bind(this);
  }
  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      case "terms":
        history.push("/Terms");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "cart":
        history.push("/Cart");
        break;
      case "signIn":
        history.push("/SignIn");
        break;
      case "signUp":
        history.push("/SignUp");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="signUp-page">
          <div className="left">
            <div className="img">
              <img src={sign} alt="" />
            </div>
            <div className="bottom-box">
              <p>
                “Having a digital space to brainstorm your ideas gives everyone
                a voice. Using the brainstorming template gave everyone equal
                input.”
              </p>
            </div>
          </div>
          <div className="right">
            <div className="contain">
              <h1>Get started absolutely free</h1>
              <h6>
                Already have an InVision account?{" "}
                <span onClick={() => this.routePage("signIn")}>Sign in</span>
              </h6>
              <p>Work email address</p>
              <input
                type="text"
                placeholder="Enter your work email and we will search for teams you can join"
              />
              <p>Your name</p>
              <input type="text" />
              <p>Your role</p>
              <input type="text" placeholder="Enter your role" />
              <p>Password</p>
              <input type="text" placeholder="6+ characters" />
              <div className="btn">Sign up</div>
              <div className="policy">
                By signing up, I agree to InVision's{" "}
                <span onClick={() => this.routePage("terms")}>
                  Terms of Service
                </span>{" "}
                and{" "}
                <span onClick={() => this.routePage("privacy")}>
                  Privacy Policy
                </span>
                .
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SignUp;
