import React, { Component } from "react";
import "../../style/common/footerBar.scss";
import footLogo from "../../image/foot-logo.png";

class FooterBar extends Component {
  constructor(props) {
    super(props);
    this.routePage = this.routePage.bind(this);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "about":
        history.push("/About");
        break;
      case "contact":
        history.push("/Contact");
        break;
      case "shipping":
        history.push("/Shipping");
        break;
      case "terms":
        history.push("/Terms");
        break;
      case "security":
        history.push("/Security");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="footerBar">
          <li onClick={() => this.routePage("privacy")}>
            プライバシーポリシー
          </li>
        </div>
      </React.Fragment>
    );
  }
}

export default FooterBar;
