import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import React, { Component } from "react";
import "../style/contact.scss";
import contact from "../image/contact.webp";

class ContacUs extends Component {
  constructor(props) {
    super(props);
    this.routehome = this.routehome.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  routehome() {
    let { history } = this.props;
    history.push("/");
  }

  render() {
    return (
      <React.Fragment>
        <div className="header-bar">
          <h3 onClick={this.routehome}>Swooo</h3>
        </div>
        <div className="contact-page">
          <div className="contain">
            <div className="title">資料請求フォーム</div>
            <div className="row">
              <div className="col">
                <div className="title">
                  当社にご興味をもっていただきありがとうございます。
                  フォーム入力後、ご入力いただいたメールアドレスに資料が送付されます。
                </div>
                <div className="tip">取引実績</div>
                <div className="img">
                  <img src={contact} alt="" />
                </div>
              </div>
              <div className="col">
                {/* <div className="second-head">
                  <div className="content">
                    <div className="li">
                      <div className="step"></div>
                      <div className="title">
                        step1
                        <br />
                        基本情報の入力
                      </div>
                    </div>
                    <div className="li">
                      <div className="step"></div>
                      <div className="title">
                        step2 <br />
                        相談内容の入力
                      </div>
                    </div>
                    <div className="li">
                      <div className="step"></div>
                      <div className="title">
                        step3 <br />
                        相談日時の選択
                      </div>
                    </div>
                    <div className="li">
                      <div className="step"></div>
                      <div className="title">
                        step4 <br />
                        送信完了
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="form">
                  <div className="input-box">
                    <div className="title">
                      氏名 <span>*</span>
                    </div>
                    <input type="text" placeholder="" />
                  </div>
                  <div className="input-box">
                    <div className="title">
                      お電話番号 <span>*</span>
                    </div>
                    <input type="text" />
                  </div>
                  <div className="input-box">
                    <div className="title">
                      メールアドレス <span>*</span>
                    </div>
                    <input type="text" placeholder="" />
                  </div>
                  <div className="input-box">
                    <div className="title">補足</div>
                    <textarea></textarea>
                  </div>
                  <div className="input-box">
                    <div className="btn">送信する</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ContacUs;
